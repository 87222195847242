// websocket实时通讯
import service from '../request'
const baseUrl = window.globalUrl.HOME_API;
// 发送消息
export const SendMessage= (params) => {
  return service({
    url: `${baseUrl}/trade-website/online/send`,
    method: 'post',
    data: params,
  })
}
//查询待办任务列表
export const selectRunTaskList = (params) => {
  return service({
    url: `${baseUrl}/trade-website/startEvent/selectRunTaskList`,
    method: 'get',
    params:params,
  })
}

//查询已办任务列表
export const selectHistoryProcess = (params) => {
  return service({
    url: `${baseUrl}/trade-website/startEvent/selectHistoryProcess`,
    method: 'get',
    params:params,
  })
}

//查询待办任务详情办理
export const selectCirculationApproval = (params) => {
  return service({
    url: `${baseUrl}/trade-website/cqjy/selectCirculationApproval`,
    method: 'get',
    params:params,
  })
}

//拒绝任务
export const rejectWorkflow = (params) => {
  return service({
    url: `${baseUrl}/trade-website/startEvent/rejectWorkflow`,
    method: 'post',
    params:params,
  })
}

//流程记录
export const selectHistoryTaskByProcessInstanceId = (params) => {
  return service({
    url: `${baseUrl}/trade-website/startEvent/selectHistoryTaskByProcessInstanceId`,
    method: 'get',
    params:params,
  })
}

//查看备份后的合同
export const selectBackUpContract = (params) => {
  return service({
    url: `${baseUrl}/trade-website/cqjy/selectBackUpContract`,
    method: 'get',
    params:params,
  })
}

//查看审批后的其他材料
export const selectApprovalOtherMaterials = (params) => {
  return service({
    url: `${baseUrl}/trade-website/cqjy/selectApprovalOtherMaterials`,
    method: 'get',
    params:params,
  })
}
//查询流程引擎列表
export const selectFatherKey = (params) => {
  return service({
    url: `${baseUrl}/trade-website/startEvent/selectFatherKey`,
    method: 'get',
    params:params,
  })
}
//查询流程图
export const selectProcess = (params) => {
  return service({
    url: `${baseUrl}/trade-website/startEvent/selectProcess`,
    method: 'get',
    params:params,
  })
}
//分配负责人
export const assigneeConfig = (params) => {
  return service({
    url: `${baseUrl}/trade-website/startEvent/assigneeConfig`,
    method: 'post',
    params:params,
  })
}
// 审批管理

export const selectPdfApproval = (params) => {
  return service({
    url: `${baseUrl}/trade-website/cqjy/selectPdfApproval`,
    method: 'get',
    params:params,
    // responseType: 'blob'
  })
}



export const deleteAssigneeConfig = (params) => {
  return service({
    url: `${baseUrl}/trade-website/startEvent/deleteAssigneeConfig`,
    method: 'delete',
    params:params
  })
}
//下载年度流转审批表
export const selectBatchPdf = (params) => {
  return service({
    url: `${baseUrl}/trade-website/cqjy/selectBatchPdf`,
    method: 'get',
    params:params,
    // responseType: 'blob'
  })
}
